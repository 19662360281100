import React, { Component } from 'react';



class PageTitle extends Component {

    render() {
        const { title, subTitle } = this.props
        return (
            <div
                style={{
                    height: 300, width: "100%", top: -30, position: "relative", objectFit: "cover",
                    backgroundColor: "rgb(106, 80, 51)", boxShadow: "0px 0px 30px 4px rgba(0, 0, 0, 0.362)"
                }}
            >
                <img
                    src='assets/img/â__Pngtreeâ__futicshapeabstractbackgroundchemistry_1250295.jpg'
                    style={{
                        height: 300, width: "100%", position: "absolute", objectFit: "cover", opacity: 0.3
                    }}
                />
                <div style={{
                    height: "100%", width: "100%", position: "relative", display: "flex",
                    justifyContent: "space-between", alignItems: "flex-end",
                    padding: 20, paddingLeft: 50
                }}>
                    <div>
                        <h1 style={{ color: "white", fontSize: 60, fontWeight: "bolder", textTransform: "uppercase" }}>{title}</h1>
                        <p style={{ color: "white", fontSize: 20 }}>Home <i class="fas fa-angle-double-right" style={{ fontSize: 15 }}></i>  { subTitle ? subTitle : title}</p>
                    </div>
                    {this.props.subIcon ?
                        <div>
                            <img src={this.props.subIcon} style={{height: 200, width: 200, objectFit: "cover", borderRadius: 10, marginRight: 30}}/>
                        </div>
                        :
                        null}
                </div>
            </div>
        )
    }
}

export default PageTitle;