import React, { Component } from 'react';

class Statitices extends Component {

    render() {
        return (
            <section class="Statitices">
                <div>
                    <i class="fas fa-hand-holding-heart"></i>
                    <h1>256</h1>
                    <p>Volunteers</p>
                </div>
                <div>
                    <i class="fas fa-piggy-bank"></i>
                    <h1>10,000$</h1>
                    <p>Donation</p>
                </div>
                <div>
                    <i class="fas fa-seedling"></i>
                    <h1>86</h1>
                    <p>Campaign</p>
                </div>
                <div>
                    <i class="fas fa-medal"></i>
                    <h1>22</h1>
                    <p>Awards</p>
                </div>
            </section>
        )
    }
}

export default Statitices