import React, { Component } from 'react';
import Calendar from 'react-calendar';
// import DropdownList from "react-widgets/DropdownList";

class TheJoshuaCoalition_ extends Component {

    render() {
        return (
            <section class="OurProgServices_1"
                style={{
                    overflow: "hidden", position: "relative", display: "flex", flexDirection: "column",
                    justifyContent: "center", alignItems: "center",
                }}>


                <div className='row' style={{ paddingLeft: "5vw", paddingRight: "5vw", position: "relative", overflow: "hidden" }}>
                    <div className='col-md-6' style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "0%" }}>
                        <div style={{ height: "50vh", width: "100%", }}>
                            <img src='assets/img/241510120_235649281843979_5935109471248854482_n.jpg' style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                        </div>
                    </div>
                    <div className='col-md-6' style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "2vw", paddingLeft: "2vw" }}>
                        <div>
                            <p style={{ letterSpacing: 2, marginBottom: 20 }}>
                                The Joshua Project Coalition programs and services address racial inequalities, health and well-
                                being, quality education, economic growth, innovation and infrastructure. Our coalition invites
                                community partners that work within these core fields to be a part of our network to help initiate
                                change in the communities that we all serve.
                            </p>
                            <a style={{ padding: 10, backgroundColor: "tomato", color: "white" }}>Schedule Appointment</a>
                        </div>
                    </div>
                </div>

                <div className='ArAbout_1ticle_row row' style={{ overflow: "hidden", position: "relative", margin: "3vw" }}>
                    <div className='slash2' style={{ zIndex: 0, top: -200 }}></div>
                    <div className='slash2' style={{ zIndex: 0, top: -100 }}></div>
                    <div style={{ position: "relative" }}>
                        <h1>PURPOSE</h1>
                        <p>
                            The purpose of this project is to end racism, prejudice, social injustice, and poverty; by building awareness,
                            solutions, and leadership for racial justice by generating trans-formative ideas, information, and experiences.
                            We have strategically built a service model that invites other community partners to the coalition to provide
                            services to address racial equity, social solutions, and infrastructure development by providing tools,
                            resources, policy change initiative, education, training and development. Currently we have partnered with
                            community partner organizations located in Texas, New York, California, Georgia, Ghana, Soweto, Zimbabwe,
                            and Nigeria to provide services to undeserved communities in these locations.
                        </p>
                        <h4>We are seeking to expand the coalition and it's work to fight against social injustices.</h4>
                        <a><i class="fab fa-facebook-f"></i> CLICK TO JOIN!</a>
                    </div>
                </div>

                <div className='row' style={{ paddingLeft: "5vw", paddingRight: "5vw", position: "relative", overflow: "hidden", marginBottom: 10, background: "url(assets/img/pexels-christina-morillo-1181304-scaled.jpg) center", backgroundSize: "cover", paddingTop: 60, paddingBottom: 200, boxShadow: "0px 0px 40px 5px rgba(0,0,0,0.5)" }}>
                    <div className='row' style={{ 
                        backgroundColor: "rgba(0,0,0,0.6)",boxShadow: "0px 0px 20px 5px rgba(0,0,0,0.5)", padding: 40, paddingLeft: 0, paddingRight: 0
                    }}>
                        <div className='col-md-6' style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "2vw", paddingLeft: "2vw", }}>
                            <div>
                                <h1 style={{ color: "white" }}>OUR SERVICES</h1>
                                <p style={{ letterSpacing: 2, marginBottom: 20, color: "white" }}>
                                    What you will receive when you join our coalition: Grant writing training and education, Network
                                    and Partner Access/ including your website linked as a community partner, Non-profit start up training,
                                    1023 EZ assistance, Coaching and Mentorship
                                </p>
                                <p style={{ letterSpacing: 2, marginBottom: 20, color: "white" }}>
                                    <i class="fas fa-user-clock"></i> Schedule Your Consultation | <i class="far fa-paper-plane"></i> Do you just need assistance with applying for your Non-Pro t Exemption?
                                </p>
                                {/* <a style={{ padding: 10, backgroundColor: "tomato", color: "white" }}>Schedule Appointment</a> */}
                                <div style={{ padding: "1vw", backgroundColor: "rgba(0,0,0,0.2)", color: "white", borderRadius: 10 }}>
                                    <h4>Schedule Your Appointment</h4>
                                    <p>Full name</p>
                                    <input type={"text"} placeholder="john doe" />
                                    <p>Email</p>
                                    <input type={"text"} placeholder="johndoe@email.com" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6' style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "2vw", paddingLeft: "2vw", }}>
                            <div style={{ height: "50vh", width: "100%", }}>
                                {/* <DropdownList
                                defaultValue="Yellow"
                                data={["Red", "Yellow", "Blue", "Orange"]}
                            /> */}
                                <Calendar />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}

export default TheJoshuaCoalition_