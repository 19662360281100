import React, { Component } from 'react';
import OurMission from '../sections/OurMission';
import Testimonials from '../sections/testimonial';
import Statitices from '../sections/Statitices';
import Footer from '../statics/footer';
import Nav from '../statics/nav';
import OurImpact from '../sections/OurImpact';
import FirstQuote from '../sections/FirstQuote';
import Article from '../sections/Article';
import SecondQuote from '../sections/SecondQuote';
import OurPrograms from '../sections/OurPrograms';
import Women_wellnes from '../sections/Women_wellnes';
import Our_Community_Partners_Sponsors from '../sections/Our_CommunityPartners_Sponsors';
import ChaseEducationalCourses from '../sections/ChaseEducationalCourses';
import PopUp from '../sections/PopUp';
// import ChaseEducationalCourses from '../sections/Women_wellnes';
const { innerHeight, innerWidth } = window

class Home extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		return (
			<div>
				<Nav parent="home" />
				<Testimonials />
				<OurMission />
				<Statitices />
				<ChaseEducationalCourses />
				<OurImpact />
				<video width={innerWidth - 10} controls >
					<source src="assets/img/Ghana_Clean_Water_Project.mp4" type="video/mp4" />
				</video>
				<Our_Community_Partners_Sponsors />
				<FirstQuote />
				<Women_wellnes parent="home"/>
				<OurPrograms />
				<Article />
				<SecondQuote />
				<Footer />
				<PopUp state={this.props.state}/>
			</div>
		)
	}
}

export default Home