import React, { Component } from 'react';

class OurProgServices_2 extends Component {

    render() {
        return (
            <section class="OurProgServices_2"
                style={{
                    overflow: "hidden", position: "relative", display: "flex", flexDirection: "column",
                    justifyContent: "center", alignItems: "center"
                }}>

                <div className='row' style={{
                    width: "100vw", overflow: "hidden", position: "relative", display: "flex", flexDirection: "column",
                    justifyContent: "center", alignItems: "center", backgroundColor: "#F2F1F2", padding: "10px !important"
                }}>
                    <img src='assets/img/blog_6_.jpg' style={{ width: "100vw", height: "100%", position: "absolute", zIndex: 0, opacity: 0.1, objectFit: "cover" }} />
                    <div className='row' style={{ width: "80vw", position: "relative" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <hr style={{ width: "30vw", borderColor: "black" }} />
                            <h1 style={{ fontSize: 27, letterSpacing: 2, margin: "4vw", width: "30vw", textAlign: "center" }}>We Offer</h1>
                            <hr style={{ width: "30vw", borderColor: "black" }} />
                        </div>
                        <ul style={{ fontSize: 17, letterSpacing: 2, }}>
                            <li>
                                <span style={{ fontWeight: "bolder" }}>Self-Help Groups:</span> Our self help groups are designed to allow others to share their information
                                from their own lives while continuing to grow beyond their individual traumas. Our groups are
                                psycho-educational in nature, providing important information about dealing with an illness or challenge.
                            </li>
                            <li>
                                <span style={{ fontWeight: "bolder" }}>Fitness Therapy:</span> Physical activity and exercise helps to alleviate some of the symptoms associated with
                                some mood disorders as well as improve self-image and cognitive functioning. We understands the importance
                                of utilizing fitness and treating the body as well as the mind. <span style={{ fontWeight: "bold" }}>Join our FAB & FIT workout group every
                                    Saturday Morning at 8:00 AM in Temecula, CA</span>
                            </li>
                            <li>
                                <span style={{ fontWeight: "bolder" }}>Art Therapy:</span> Some individuals have issues with verbal communication. We utilize art therapy as a means of
                                nonverbal communication to assist clients with being able to convey emotions and feelings they may not
                                feel comfortable talking about. It also provides a means to explore, understand, resolve issues one may
                                have experienced in their lives.
                            </li>
                            <li>
                                <span style={{ fontWeight: "bolder" }}>Nutrition Therapy:</span> We understand that a healthful lifestyle can promote mental health. Those that are battling
                                substance abuse and mental health issues, nutrition plays the same key role in maintaining recovery while also
                                improving the resulting health conditions and deficiencies. Clients participating in the program meet one-on-one
                                with our nutritionist, establishing health eating habits, knowledge regarding the foods that are most beneficial
                                for them during and after treatment.
                            </li>
                            <li>
                                <span style={{ fontWeight: "bolder" }}>Spiritual Counseling:</span> We understand that the human spirit is intricately involved in the healing of the the
                                total person- mind, body, and soul. Our holistic counselors look at a client’s presenting symptoms and
                                issues and evaluate how the physical body is involved and affected with the goal of facilitating a sense
                                of physical well-being. Our counselors have a spectrum of areas in which they operate: Reiki-based
                                treatment, yoga instruction, energy healing, and more.
                            </li>
                        </ul>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <hr style={{ width: "30vw", borderColor: "black" }} />
                            <a style={{ width: "30vw", height: "fit-content", padding: 10, paddingLeft: 20, paddingRight: 20, backgroundColor: "tomato", margin: "4vw", borderRadius: 20, color: "white", textAlign: "center" }}>Click to register</a>
                            <hr style={{ width: "30vw", borderColor: "black" }} />
                        </div>
                    </div>
                </div>

                <div className='row' style={{
                    width: "80vw", display: "flex", flexDirection: "column",
                    justifyContent: "center", alignItems: "center"
                }}>
                    <h1 style={{ fontWeight: "bolder", fontSize: "x-large", textAlign: "center", marginTop: 40 }}>WELLNESS WOMEN’S SUPPORT SEMINARS</h1>
                    <hr style={{ width: "20vw", borderColor: "black" }} />
                    <p>
                        These seminars are designed to inspire, empower, motivate, encourage, and to move women forward in
                        their divine healing and in to their designed purpose. By attending, women will have the opportunity
                        to hear extraordinary breakout session speakers share their experiences and receive practical
                        tools and resources to not just live, but to soar beyond their current situations. Seminar
                        topics: How to detect mental illness, How to cope with mental illness, mindful wellness, better
                        living, art therapy, business strategies, financial empowerment, keys to living a success life, and much more.
                    </p>
                </div>

                <div className='row' style={{
                    width: "100vw", overflow: "hidden", position: "relative", display: "flex", flexDirection: "column",
                    justifyContent: "center", alignItems: "center", backgroundColor: "#F2F1F2", padding: "10px !important", marginBottom: 10
                }}>
                    <img src='assets/img/218036353_146968374201847_1134335439513124336_n.jpg' style={{ width: "100vw", height: "100%", position: "absolute", zIndex: 0, opacity: 0.4, objectFit: "cover" }} />
                    <h1 style={{ fontWeight: "bolder", textAlign: "center", fontSize: 25, letterSpacing: 2, marginTop: 40, position: "relative" }}>LIFE SKILLS TRAINING</h1>
                    <p style={{ textAlign: "center", letterSpacing: 2, padding: 30, position: "relative" }}>
                        Our life skills training program provides curriculum addressing social, psychological,
                        cognitive, and attitudinal factors associated with mental health. Our primary objective is
                        to enhance the development of basic life skills, personal competence, and skills related to
                        resistance to social influences that promote mental illnesses.
                    </p>
                    <div className='row' style={{ position: "relative", paddingBottom: 20 }}>
                        <div className='col-md-6' style={{ padding: "0.4vw", }}>
                            <div style={{
                                backgroundColor: "rgba(255,255,255,0.4)", backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)",
                                borderRadius: 10, padding: "1vw",
                            }}>
                                <h3>Independent Living</h3>
                                <p>
                                    Personal Living Skills (Socialization, Personal Hygiene, Health Care, Nutrition,
                                    Medication Management), Home Living Skills (Clothing Care, Meal Plan and
                                    Preparation, Home Care, Home Maintenance and Safety), and Community Living
                                    Skills (Social Interaction, Mobility & Travel Training,Transportation Time
                                    Management, Community Safety and Participation).
                                </p>
                            </div>
                        </div>

                        <div className='col-md-6' style={{ padding: "0.4vw", }}>
                            <div style={{
                                backgroundColor: "rgba(255,255,255,0.4)", backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)",
                                borderRadius: 10, padding: "1vw",
                            }}>
                                <h3>Communication</h3>
                                <p>
                                    Manners, Dating and Relationships, Interpersonal Communications, Workplace
                                    Communication, Problem Solving, Decision Making and Social Skills Training.
                                </p>
                            </div>
                        </div>

                        <div className='col-md-6' style={{ padding: "0.4vw", }}>
                            <div style={{
                                backgroundColor: "rgba(255,255,255,0.4)", backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)",
                                borderRadius: 10, padding: "1vw",
                            }}>
                                <h3>Emotional Regulation/Intelligence</h3>
                                <p>
                                    Social Skills Curriculum, Stress Management, Anger Management, Self-Esteem,
                                    Conflict Resolution, Relationship Building, and the Impacts of behavior on self and others.
                                </p>
                            </div>
                        </div>

                        <div className='col-md-6' style={{ padding: "0.4vw", }}>
                            <div style={{
                                backgroundColor: "rgba(255,255,255,0.4)", backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)",
                                borderRadius: 10, padding: "1vw",
                            }}>
                                <h3>Post Secondary Learning</h3>
                                <p>College Prep Programs, College Support Programs, Post Secondary connections to agencies for life learning.</p>
                            </div>
                        </div>

                        <div className='col-md-6' style={{ padding: "0.4vw", }}>
                            <div style={{
                                backgroundColor: "rgba(255,255,255,0.4)", backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)",
                                borderRadius: 10, padding: "1vw",
                            }}>
                                <h3>Employment Skills</h3>
                                <p>
                                    Job Skills, Attitudes and Performance, Self Evaluation, Feedback, Job Search,
                                    Job retention, Strengths and Weaknesses, Volunteer Opportunities, Work ethic, Job Placement.
                                </p>
                            </div>
                        </div>

                        <div className='col-md-6' style={{ padding: "0.4vw", }}>
                            <div style={{
                                backgroundColor: "rgba(255,255,255,0.4)", backdropFilter: "blur(5px)", WebkitBackdropFilter: "blur(5px)",
                                borderRadius: 10, padding: "1vw",
                            }}>
                                <h3>Money Management</h3>
                                <p>
                                    Focuses on Daily Budgets, Understanding your Paycheck,  Filing Taxes, Borrowing Money, Credit,
                                    Financial Assistance for Education, and Checking and Savings Accounts.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='row' style={{ backgroundColor: "rgba(191, 180, 180, 0.278)", padding: 30, width: "100vw", marginBottom: 10, }}>
                    <div style={{ width: "100%", position: "relative", overflow: "hidden", boxShadow: "0px 0px 30px 5px rgba(0,0,0,0.7)" }}>
                        <img src='assets/img/gallery_page_9_popup.jpg' style={{ height: "100%", width: "100%", objectFit: "cover", position: "absolute", zIndex: 0, left: 0 }} />
                        <div style={{ height: "100%", width: "100%", backgroundColor: "rgba(0,0,0,0.5)", position: "absolute", zIndex: 0, left: 0 }}></div>
                        <div className='row' style={{ zIndex: 2, position: "relative" }}>
                            <div className='col-md-6' style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <h1 style={{ margin: "3vw", color: "white", marginBottom: 10 }}>Community Health Screenings</h1>
                                <p style={{ margin: "3vw", color: "white", marginTop: 10 }}>
                                    Our community health screenings provides knowledge and promotes health.
                                    We partner with local community health care agencies, medical professionals, and local
                                    government officials to offer services that will make a lasting impact.
                                </p>
                            </div>
                            <div className='col-md-6'>
                                <p style={{ margin: "3vw", color: "white", marginBottom: 10 }}>Services offered during our community health screenings:</p>
                                <ul style={{ margin: "3vw", color: "white", marginTop: 10 }}>
                                    <li>Body Mass Index</li>
                                    <li>Diabetes Test</li>
                                    <li>Cholesterol Test</li>
                                    <li>Blood Pressure Reading</li>
                                    <li>Smoking Risk Assessment</li>
                                    <li>Alcohol Abuse Screening</li>
                                    <li>Anxiety, Stress, Depression Screening</li>
                                    <li>Review Results with Peer Health Specialist</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row' style={{ backgroundColor: "rgba(191, 180, 180, 0.278)", padding: 30, width: "100vw", marginBottom: 10, }}>
                    <div style={{ width: "100%", position: "relative", overflow: "hidden", boxShadow: "0px 0px 30px 5px rgba(0,0,0,0.7)" }}>
                        <img src='assets/img/vol.png' style={{ height: "100%", width: "100%", objectFit: "cover", position: "absolute", zIndex: 0, left: 0 }} />
                        <div style={{ height: "100%", width: "100%", backgroundColor: "rgba(0,0,0,0.5)", position: "absolute", zIndex: 0, left: 0 }}></div>
                        <div className='row' style={{ zIndex: 2, position: "relative" }}>
                            <div className='col-md-12' style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <h1 style={{ margin: "3vw", color: "white", marginBottom: 10 }}>COMMUNITY MENTAL HEALTH SERVICES</h1>
                                <p style={{ margin: "3vw", color: "white", marginTop: 10 }}>
                                    Mental health illnesses affect a large proportion of the urban population. 1 in 4
                                    people will have a mental health disorder in their lifetime, and this can occur at
                                    any age. ROJCD offers treatment services from a holistic approach. Our approach of
                                    treating the whole body provides the best outcomes in the recovery process. We treat
                                    the underlying issues that trigger addiction, helping to maintain the balance of
                                    career, social and family life. Our psychosocial approach looks at individuals in
                                    the context of the combined influence that psychological factors and the surrounding
                                    social environment have on their physical and mental wellness and their ability to
                                    function. We also offer the holistic approach component to enrich the treatment
                                    services we offer. Our primary goal is to care for the whole person (mind, body, and soul).
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
        )
    }
}

export default OurProgServices_2